<template>
  <v-container class="mt-3 mb-16">
    <div class="banner-with-text">
      <label for="">ตั้งค่ารหัสผ่านใหม่</label>
    </div>
    <div style="margin-top:20px">
      <v-text-field
        v-model="passwordValue"
        :rules="passwordValueRules"
        class="field"
        type="password"
        outlined
        placeholder="รหัสผ่านใหม่"
        @keyup="passwordCheck"
      />
    </div>
    <div>
      <v-text-field
        id="confirm-new-password"
        v-model="confirmPasswordValue"
        class="field"
        type="password"
        outlined
        placeholder="ยืนยันรหัสผ่าน"
        autocomplete="confirm-new-password"
        @keyup="passwordConfirmationCheck"
        @blur="passwordConfirmationCheck"
        :error="confirmPasswordError"
        :error-messages="confirmPasswordErrorMessage"
      />
    </div>
    <div style="text-align:center">
      <v-btn
        class="px-12 resetpassword_btn"
        :loading="loading"
        :disabled="loading || !submitAble"
        @click="onSubmit"
      >
        <span>เปลี่ยนรหัสผ่าน</span>
      </v-btn>
    </div>
  </v-container>
</template>
<script>
import { mapActions } from 'vuex';
import { CHANGE_SNACKBAR_MESSAGE, SHOW_SNACKBAR, CHANGE_SNACKBAR_COLOR } from '@/store/_actionTypes';
import TextField from '@/components/TextField.vue';
import { reset } from '@/services/resetpassword';

export default {
  name: 'ForgetPassword',
  components: {
    TextField,
  },
  data: () => ({
    loading: false,
    passwordValue: '',
    passwordValueRules: [
      (v) => !!v || 'กรุณากรอกรหัสผ่าน',
    ],
    confirmPasswordValue: '',
    confirmPasswordError: false,
    confirmPasswordErrorMessage: '',
  }),
  computed: {
    submitAble() {
      if (this.passwordValue.length === 0)
        return false;

      if (this.passwordValue !== this.confirmPasswordValue) {
        return false;
      }

      return true;
    }
  },
  methods: {
    ...mapActions('app', [SHOW_SNACKBAR, CHANGE_SNACKBAR_MESSAGE, CHANGE_SNACKBAR_COLOR]),
    passwordCheck(e) {
      if (!!this.confirmPasswordValue && this.confirmPasswordValue !== e.target.value) {
        this.confirmPasswordError = true
        this.confirmPasswordErrorMessage = 'รหัสผ่านไม่ตรงกัน';
        return;
      }

      this.confirmPasswordError = false;
      this.confirmPasswordErrorMessage = '';
    },
    passwordConfirmationCheck(e) {
      if (this.confirmPasswordValue === '') {
        this.confirmPasswordError = true
        this.confirmPasswordErrorMessage = 'กรุณากรอกยืนยันรหัสผ่าน';
        return;
      }

      if (this.passwordValue !== e.target.value) {
        this.confirmPasswordError = true
        this.confirmPasswordErrorMessage = 'รหัสผ่านไม่ตรงกัน';
        return;
      }

      this.confirmPasswordError = false;
      this.confirmPasswordErrorMessage = '';
    },
    async onSubmit() {
      if (this.confirmPasswordValue === '') {
        this.confirmPasswordError = true
        this.confirmPasswordErrorMessage = 'กรุณากรอกยืนยันรหัสผ่าน';
        return
      }

      try {
        this.loading = true;
        await reset(this.$route.query.email, this.$route.query.token, this.passwordValue);
      } catch (error) {
        this[SHOW_SNACKBAR](true);
        this[CHANGE_SNACKBAR_COLOR]('red');
        this[CHANGE_SNACKBAR_MESSAGE](error.response.data.error);
        this.loading = false;
        return;
      }

      setTimeout(() => {
        this[SHOW_SNACKBAR](true);
        this[CHANGE_SNACKBAR_COLOR]('green');
        this[CHANGE_SNACKBAR_MESSAGE]('เปลี่ยนรหัสผ่านเรียบร้อยแล้ว');
        this.loading = false;
        this.email = '';
        this.$router.push({ name: 'Shop' });
      }, 1000);
    }
  }
};
</script>
<style scoped lang="scss">
.resetpassword_btn {
  background-color: $primary-color-02 !important;
  font-family: Kanit;
  font-size: 16px;
  color: $white-01;
}

.banner-with-text {
  height: 120px;
  background-color: #f6efff;
  width: 100%;
  display: flex;
}

.banner-with-text > * {
  display: inline-flex;
}

.banner-with-text > label {
  font-family: Kanit;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.banner-with-text > img {
  margin-left: auto;
}

.info-paragraph {
    margin: 12px 0px;
    display: flex;
    flex-direction: column;
}

.info-paragraph > ul {
    margin-top: 32px;
}

.info-paragraph > ul > li {
    font-family: Sarabun;
    font-size: 16px;
    font-weight: 600;
    margin-left: 32px;
}

</style>