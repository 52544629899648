import { post } from '@/helpers/axios';

export const request = (email) => {
  try {
    const body = {
      email
    };
    const result = post('/password/request', body);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const reset = (email, token, password) => {
  try {
    const body = {
      email,
      token,
      password
    };
    const result = post('/password/reset', body);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default {};
