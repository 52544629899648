<template>
  <div class="text-field">
    <div
      v-if="title"
      class="title-text mb-3"
    >
      {{ title }}
    </div>
    <v-text-field
      v-model="inputValue"
      class="field"
      :rules="rules"
      :label="label"
      :placeholder="placeHolder"
      :required="required"
      :outlined="outlined"
      :solo="solo"
      :filled="filled"
    />
  </div>
</template>

<script>
export default {
  name: 'TextField',
  props: {
    value: {
      type: String,
      default: () => '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: () => 'text',
    },
    title: {
      type: String,
      default: () => '',
    },
    label: {
      type: String,
      default: () => '',
    },
    placeHolder: {
      type: String,
      default: () => '',
    },
    requireTitle: {
      type: Boolean,
      default: () => true,
    },
    required: {
      type: Boolean,
      default: () => true,
    },
    outlined: {
      type: Boolean,
      default: () => true,
    },
    solo: {
      type: Boolean,
      default: () => false,
    },
    filled: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('setValue', value);
      },
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/variables';

.text-field {
  .title-text {
    font-family: Kanit;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: start;
  }

  .field {
    .v-input__slot {
      margin-bottom: 0 !important;
      min-height: 0px !important;
    }

    .v-text-field__details {
      margin-bottom: 0 !important;
    }

    .v-messages__message {
      margin: 12px 0 12px 0 !important;
    }

    .v-text-field__slot {
      height: 41px !important;
    }

    fieldset {
      height: 48px !important;
    }
  }
}
</style>
